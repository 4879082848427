import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

import bg from '../../images/bg_white.svg'

const Intro = ({ data, img, positionY, positionBottom, video, src, videoSection }) => {
  if (video) {
    return (
      <StyledIntro >
        <div className="container-video">
          <video autoPlay={true} muted playsinline controls poster={src.poster.publicURL} className="video">
            <source src={src.video.publicURL} type="video/mp4" />
          </video>
        </div>
        <div className="content">
          <p className={font.rSB + " title"}>{data.title}<span className="smallUnderline" /></p>
          <p className={font.rM + " text"}>{data.text}</p>
        </div>
      </StyledIntro>
    )
  }
  if (videoSection) {
    return (
      <StyledOnlyIntro >
        <video autoPlay={true} muted playsinline controls poster={src.poster.publicURL} className="video">
          <source src={src.video.publicURL} type="video/mp4" />
        </video>
      </StyledOnlyIntro>
    )
  }
  return (
    <StyledIntro img={img} bg={bg} positionY={positionY} positionBottom={positionBottom}>
      <div className="img" />
      <div className="content">
        <p className={font.rSB + " title"}>{data.title}<span className="smallUnderline" /></p>
        <p className={font.rM + " text"}>{data.text}</p>
      </div>
    </StyledIntro>
  )
}

export default Intro

const StyledOnlyIntro = styled.div`
.video{
  background-repeat:no-repeat;
  width: 100%;
  z-index: -1;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  top: 0%;
  left: 0%;
  transform: translate(0%, 0%);
}
`


const StyledIntro = styled.div`
  position: relative;
  margin-top: -1px;
  padding-top: 1px;
  .container-video{
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 90vh;
  }
  .video{
    background-repeat:no-repeat;
    width: 100%;
    height: 90vh;
    z-index: -1;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
  }
  .img{
    background-image: url(${props => (props.img && props.img)});
    background-size: cover;
    background-position: ${props => props.positionY ? '0 -80px' : props.positionBottom ? 'bottom' : 'center'};
    background-repeat:no-repeat;
    width: 100%;
    /* height: 60%; */
    height: 600px;
    z-index: -1;
    /* position: absolute; */
    top: 0;

    @media only screen and (max-width: 1025px){
      height: 500px;
    }

    @media only screen and (min-width: 2000px){
      height: 900px;
    }
  }
  .content{
    width: calc(630px + 400 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      width: 950px;
    }
    /* margin: 10vw auto 0; */
    margin: 0 auto;
    /* padding: 5% 6%; */
    padding: 5% 0;
    background-color: #fff;
    position: relative;
    .title{
      font-size:calc(22px + 4 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px){
        font-size: 26px;
      }
      letter-spacing: 0.1em;
      color: #231f20;
      position: relative;
      margin-bottom: 2em;
      .smallUnderline{
        width: 2em;
        height: 0.25em;
        background-color: #b7a99a;
        position: absolute;
        top: 1.6em;
        left: 0;
      }
    }
    .text{
      font-size: calc(12px + 10 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px){
        font-size: 22px;
      }
      letter-spacing: 0.1em;
      line-height: 1.6em;
      color: #808080;
      white-space: pre-wrap;
    }
  }
`