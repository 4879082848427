import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Intro = ({ data, img, video, src, videoSection }) => {
  if (video) {
    return (
      <StyledIntro >
        <div className="container-video">
          <video playsInline autoPlay={true} controls poster={src.poster.publicURL} className="video">
            <source src={src.video.publicURL} type="video/mp4" />
          </video>
        </div>
        <div className="content">
          <p className={font.rSB + " title"}>{data.title}<span className="smallUnderline" /></p>
          <p className={font.rM + " text"}>{data.text}</p>
        </div>
      </StyledIntro>
    )
  }
  if (videoSection) {
    return (
      <StyledIntro >
        <div className="container-video">
          <video playsInline autoPlay={true} controls poster={src.poster.publicURL} className="video">
            <source src={src.video.publicURL} type="video/mp4" />
          </video>
        </div>
      </StyledIntro>
    )
  }
  return (
    <StyledIntro img={img}>
      <div className="img" />
      <div className="content">
        <p className={font.rSB + " title"}>{data.title}<span className="smallUnderline" /></p>
        <p className={font.rM + " text"}>{data.text}</p>
      </div>
    </StyledIntro>
  )
}

export default Intro

const StyledIntro = styled.div`
  position: relative;
  margin-top: -1px;
  padding-top: 1px;
  .container-video{
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 50vh;
  }
  .video{
    background-repeat:no-repeat;
    width: 100%;
    height: 50vh;
    z-index: -1;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
  }
  .img{
    background-image: url(${props => (props.img && props.img)});
    background-size: cover;
    background-position: center;
    background-repeat:no-repeat;
    width: 100%;
    height: ${props => (props.img ? '50vh' : 'unset')}
  }
  .content{
    width: 100%;
    margin: 30px auto;
    padding: 7%;
    background-color: #fff;
    position: relative;
    .title{
      font-size: calc(16px + 10 * (100vw - 320px) / 448);
      letter-spacing: 0.1em;
      color: #231f20;
      position: relative;
      margin-bottom: 2em;
      .smallUnderline{
        width: 2em;
        height: 0.25em;
        background-color: #b7a99a;
        position: absolute;
        top: 1.6em;
        left: 0;
      }
    }
    .text{
      font-size: calc(12px + 4 * (100vw - 320px) / 448);
      letter-spacing: 0.1em;
      line-height: 1.6em;
      color: #808080;
    }
  }
`