import React from "react"
import Img from "gatsby-image"

import styled from "styled-components"

const Banner = ({ data, alt }) => {
  
  return(
    <StyledBanner>
      <Img fluid={data.childImageSharp.fluid} style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "cover" }} alt={alt && alt}/>
    </StyledBanner>
  )
}

export default Banner

const StyledBanner = styled.div`
  position: relative;
  width: calc(320px + 1298 * (100vw - 320px) / 1600);
  max-width: 100vw;
  margin: 0 auto calc(60px + 60 * (100vw - 320px) / 1600);
  @media only screen and (min-width: 1920px){
    width: 1618px;
    margin: 0 auto 120px;
  }
`