import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Info = ({ data }) => {
  
  return(
    <StyledInfo>
      <div className="passo">
        <div className={font.rEBI + " subtitle"}>{data.intro.title}</div>
        <div className={font.rR + " txt"}>{data.intro.text}</div>
      </div>

      <div className="passo">
        <div className={font.rEBI + " subtitle"}>{data.cuidados.title}</div>
        <br/>
        <ol className={font.rR + " txt"}>
          {data.cuidados.list.map((data, i)=>(
            <li key={"cuidados" + i}>{data}</li>
          ))}
        </ol>
      </div>

      <div className="passo">
        <div className={font.rEBI + " subtitle"}>{data.dormir.title}</div>
        <div className={font.rR + " txt"}>{data.dormir.text}</div>
      </div>
    </StyledInfo>
  )
}

export default Info

const StyledInfo = styled.div`
  position: relative;
  margin: 5vw auto calc(100px + 100 * (100vw - 320px) / 1600);
  width: calc(280px + 800 * (100vw - 320px) / 1600);
  @media only screen and (min-width: 1920px){
    width: 1080px;
    margin: 5vw auto 200px;
  }
  .title{
    font-size: calc(16px + 22 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px){
      font-size: 38px;
    }
    letter-spacing: 0.1em;
    color: #231f20;
    text-transform: uppercase;
    margin-bottom: 2em;
  }
  .subtitle{
    font-size: calc(14px + 10 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px){
      font-size: 24px;
    }
    letter-spacing: 0.1em;
    line-height: 1.6em;
    color: #231f20;
  }
  .txt{
    font-size: calc(12px + 12 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px){
      font-size: 22px;
    }
    letter-spacing: 0.1em;
    line-height: 1.6em;
    color: #808080;
  }
  .passo{
    margin: calc(30px + 20 * (100vw - 320px) / 1600) 0;
    @media only screen and (min-width: 1920px){
      margin: 50px 0;
    }
  }
  .titleFlex{
    display: flex;
    align-items: center;
  }
  .icon{
    width: calc(66px + 34 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px){
      width: 100px;
    }
  }
  .marginBot{
    margin-bottom: 25px;
  }
  .marginRight{
    margin-right: 15px;
  }
`