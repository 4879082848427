import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Navbar from "../../components/navbar/navbar"
import NavbarMobile from "../../components/navbar/navbarMobile"
import Intro from "../../components/servicos/intro"
import IntroMobile from "../../components/servicos/introMobile"
import Banner from "../../components/servicos/banner"
import Info from "../../components/servicos/infoProsto"
import Footer from "../../components/footer/footer"
import FooterMobile from "../../components/footer/footerMobile"
import FormServicos from "../../components/servicos/formServicos"
import FormServicosMobile from "../../components/servicos/formServicosMobile"
import Img from '../../images/servicos/imgs/prostodontia/prostodontia.jpg'

const ProstodontiaPage = ({ data }) => (
  <Layout>
    <SEO title="Prostodontia" description={data.prostodontiaJson.intro.text} />
    {useBreakpoint().mobile ? (
      <>
        <NavbarMobile />
        <IntroMobile data={data.prostodontiaJson.intro} img={Img} />
        <Banner data={data.prostodontiaJson.banner} alt={data.prostodontiaJson.alt}/>
        <Info data={data.prostodontiaJson.content} />
        <FormServicosMobile nome="Prostodontia" />
        <FooterMobile data={data.globalJson.footer}/>
      </>
    ) : (
      <>
        <Navbar />
        <Intro data={data.prostodontiaJson.intro} img={Img}/>
        <Banner data={data.prostodontiaJson.banner} alt={data.prostodontiaJson.alt}/>
        <Info data={data.prostodontiaJson.content} />
        <FormServicos nome="Prostodontia" />
        <Footer data={data.globalJson.footer}/>
      </>
    )}
  </Layout>
)

export default ProstodontiaPage

export const Json = graphql`
  query prostodontia {
    prostodontiaJson {
      intro {
        title
        text
      }
      banner {
        childImageSharp {
          fluid(quality: 100, maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      alt
      content {
        intro {
          title
          text
        }
        cuidados {
          title
          list
        }
        dormir {
          title
          text
        }
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
